import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { App, Home, NotFound } from 'containers';
import { Login } from 'containers/Login/Loadable';
import { Profile } from 'containers/Profile/Loadable';
import { Balance } from 'containers/Balance/Loadable';
import { Cabinet } from 'containers/Cabinet/Loadable';
import { Trash } from 'containers/Trash/Loadable';
import { User } from 'containers/User/Loadable';
import { Dashboard } from 'containers/Dashboard/Loadable';
import { Report, Subject, Branch, Setting, CallCentre, Analytic, Create, Survey, Interview, Interviews, SuperQr, Design } from 'containers/Survey/Loadable';
import { Tariff, Reliability, CallCenter, TypeSurvey, Education, Integration, Faq, PayCheck, MysteryShopping } from 'containers/Page/Loadable';
import Store from 'containers/Store/Loadable';

const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => state.auth.user !== null,
  wrapperDisplayName: 'UserIsAuthenticated'
});

const isNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/cabinet',
  authenticatedSelector: state => state.auth.user === null,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false
});

const userIsEmpty = connectedRouterRedirect({
  redirectPath: '/profile',
  authenticatedSelector: state => state.auth.user.firstName !== undefined,
  wrapperDisplayName: 'UserIsEmpty',
  allowRedirectBack: false
});

const userIsPurchase = connectedRouterRedirect({
  redirectPath: '/tariffs',
  authenticatedSelector: state => state.auth.user.tariff !== 1,
  wrapperDisplayName: 'UserIsPurchase',
  allowRedirectBack: false
});

const userIsZko = connectedRouterRedirect({
  redirectPath: '/cabinet',
  authenticatedSelector: state => state.auth.user.username === 'info_oral@mail.ru',
  wrapperDisplayName: 'UserIsZko',
  allowRedirectBack: false
});

const routes = [
  {
    component: App,
    routes: [
      { path: '/', exact: true, component: Home },
      { path: '/login', component: isNotAuthenticated(Login) },
      { path: '/profile', component: isAuthenticated(Profile) },
      { path: '/user', component: isAuthenticated(userIsEmpty(userIsPurchase(User))) },
      { path: '/balance', component: isAuthenticated(userIsEmpty(userIsPurchase(Balance))) },
      { path: '/cabinet', component: isAuthenticated(userIsEmpty(userIsPurchase(Cabinet))) },
      { path: '/trash', component: isAuthenticated(userIsEmpty(userIsPurchase(Trash))) },
      { path: '/survey', exact: true, component: isAuthenticated(userIsEmpty(userIsPurchase(Create))) },
      { path: '/survey/:id', exact: true, component: isAuthenticated(Survey) },
      { path: '/survey/:id/question', component: isAuthenticated(Survey) },
      { path: '/survey/:id/setting', component: isAuthenticated(Setting) },
      { path: '/survey/:id/branch', component: isAuthenticated(Branch) },
      { path: '/survey/:id/subject', component: isAuthenticated(Subject) },
      { path: '/survey/:id/design', component: isAuthenticated(Design) },
      { path: '/survey/:id/analytic', component: isAuthenticated(Analytic) },
      { path: '/survey/:id/call-centre', component: isAuthenticated(CallCentre) },
      { path: '/dashboard/:id', component: isAuthenticated(Dashboard) },
      { path: '/store', component: isAuthenticated(userIsEmpty(userIsPurchase(Store))) },
      { path: '/interview/:id/:subId', component: Interview },
      { path: '/interviews/:id', component: Interviews },
      { path: '/report-v2/:id/:superqrid/:subId/:subjectId/:startDate/:endDate', component: Report },
      { path: '/tariffs', component: Tariff },
      { path: '/reliability', component: Reliability },
      { path: '/call-center', component: CallCenter },
      { path: '/type-survey', component: TypeSurvey },
      { path: '/education', component: Education },
      { path: '/integration', component: Integration },
      { path: '/faq', component: Faq },
      { path: '/mystery-shopping', component: MysteryShopping },
      { path: '/pay/check/:id', component: PayCheck },
      { component: NotFound }
    ]
  }
];

export default routes;
