const LOAD = 'GET_SURVEY_SUBJECT_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_SUBJECT_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_SUBJECT_LOAD_FAIL';
const LOAD2 = 'GET_SURVEY_SUBJECT2_LOAD';
const LOAD_SUCCESS2 = 'GET_SURVEY_SUBJECT2_LOAD_SUCCESS';
const LOAD_FAIL2 = 'GET_SURVEY_SUBJECT2_LOAD_FAIL';
const LOAD_ANOTHER = 'GET_SURVEY_ANOTHER_SUBJECT_LOAD';
const LOAD_ANOTHER_SUCCESS = 'GET_SURVEY_ANOTHER_SUBJECT_LOAD_SUCCESS';
const LOAD_ANOTHER_FAIL = 'GET_SURVEY_ANOTHER_SUBJECT_LOAD_FAIL';
const SAVE = 'SAVE_SURVEY_SUBJECT_LOAD';
const SAVE_SUCCESS = 'SAVE_SURVEY_SUBJECT_LOAD_SUCCESS';
const SAVE_FAIL = 'SAVE_SURVEY_SUBJECT_LOAD_FAIL';
const SAVE_ANOTHER = 'SAVE_SURVEY_ANOTHER_SUBJECT_LOAD';
const SAVE_ANOTHER_SUCCESS = 'SAVE_SURVEY_ANOTHER_SUBJECT_LOAD_SUCCESS';
const SAVE_ANOTHER_FAIL = 'SAVE_SURVEY_ANOTHER_SUBJECT_LOAD_FAIL';
const EXPORT = 'EXPORT_SURVEY_SUBJECT_LOAD';
const EXPORT_SUCCESS = 'EXPORT_SURVEY_SUBJECT_LOAD_SUCCESS';
const EXPORT_FAIL = 'EXPORT_SURVEY_SUBJECT_LOAD_FAIL';
const IMPORT = 'IMPORT_SURVEY_SUBJECT_LOAD';
const IMPORT_SUCCESS = 'IMPORT_SURVEY_SUBJECT_LOAD_SUCCESS';
const IMPORT_FAIL = 'IMPORT_SURVEY_SUBJECT_LOAD_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.subject,
        photo: action.result.photo
      };
    case LOAD_SUCCESS2:
      return {
        ...state,
        subjects: action.result.subjects
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getSubject(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/subject?id=${id}`)
  };
}

export function getSubjectAll(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/subject-all?id=${id}`)
  };
}

export function getSubjectOld(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/subject-old?id=${id}`)
  };
}

export function getSubjectBranch(id) {
  return {
    types: [LOAD2, LOAD_SUCCESS2, LOAD_FAIL2],
    promise: ({ client }) => client.get(`/v1/survey/subject/branch?id=${id}`)
  };
}

export function setSubject(id, data) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ client }) => client.post('/v1/survey/subject', { id, data })
  };
}

export function getAnotherSubject(id, userId) {
  return {
    types: [LOAD_ANOTHER, LOAD_ANOTHER_SUCCESS, LOAD_ANOTHER_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/subject/another?id=${id}&userId=${userId}`)
  };
}

export function setAnotherSubject(id, userId, data) {
  return {
    types: [SAVE_ANOTHER, SAVE_ANOTHER_SUCCESS, SAVE_ANOTHER_FAIL],
    promise: ({ client }) => client.post('/v1/survey/subject/another', { id, userId, data })
  };
}

export function exportSubject(id) {
  return {
    types: [EXPORT, EXPORT_SUCCESS, EXPORT_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/subject/export?id=${id}`)
  };
}

export function importSubject(id, data) {
  return {
    types: [IMPORT, IMPORT_SUCCESS, IMPORT_FAIL],
    promise: ({ client }) => client.post('/v1/survey/subject/import', { id, data })
  };
}
