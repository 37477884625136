import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { provideHooks } from 'redial';
import { animateScroll } from 'react-scroll';
import ScrollToTop from 'react-scroll-up';
import { isLoaded as isAuthLoaded, load as loadAuth } from 'redux/modules/auth';
import ReactGA from 'react-ga';

@provideHooks({
  fetch: async ({ store: { dispatch, getState } }) => {
    if (!isAuthLoaded(getState())) {
      await dispatch(loadAuth()).catch(() => null);
    }
  }
})

@withRouter
class App extends Component {
  static propTypes = {
    route: PropTypes.objectOf(PropTypes.any),
    location: PropTypes.objectOf(PropTypes.any),
    user: PropTypes.shape({
      email: PropTypes.string
    })
  };
  static contextTypes = {
    store: PropTypes.object
  };
  static defaultProps = {
    user: null
  };
  state = {
    user: this.props.user, // eslint-disable-line react/destructuring-assignment
    prevProps: this.props // eslint-disable-line react/no-unused-state
  };
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    const { route } = this.props;
    const styles = require('./App.less');
    require('../../theme/nprogress.css');

    return (
      <>
        <Helmet
          htmlAttributes={{ lang: 'ru-RU' }}
          title="MVoter.com"
          meta={[{ name: 'description', content: 'MVoter.com' }]}
        />

        { renderRoutes(route.routes) }

        <ScrollToTop showUnder={ 300 } style={{ zIndex: '7777', display: 'none' }}>
          <div className={ styles.arrowUp } onClick={ () => animateScroll.scrollToTop() } />
        </ScrollToTop>
      </>
    );
  }
}

export default connect(
  state => ({
    user: state.auth.user
  }),
  {}
)(App);
